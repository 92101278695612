import React from 'react';

import TopBar from '../components/TopBar';
import HomeContent from '../components/HomeContent';
import RegistrationHeader from '../components/RegistrationHeader';
import Base from '../layout/base';

function Dashboard() {
  return (
    <Base>
      <div className="App">
        <TopBar />
        <div>
            projekte
        </div>
      </div>
    </Base>
  );
}

export default Dashboard;
